/* Global CSS */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #ff7e00;
  --heading-color: #ffffff;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary); 
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}
.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* Skills & About Me */
.section--title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
/* Portfolio, Testimonial & Contact Me */
.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10.333px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  right: 0;
}
.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.navbar--items ul > li > a {
  text-decoration: none;
  color: var(--black);
}
/* Navbar Content */
.navbar--content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.navbar--active-content {
  color: var(--primary);
}
/* Navbar Styler Ends */

/* Hero Section Style */
/* Hero Section Style */
/* Hero Section Style */
/* Hero Section Style */
/* Hero Section Style */
/* Hero Section Style */
/* Hero Section Style */
/* Base styles for large screens */
.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 90.333px 100.333px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-color: var(--black);
}

.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 100px;
  gap: 32px;
}

.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
  /* margin-left: 130px; */
}

.hero--section--content--box > button {
  margin-top: 21.333px;
}

.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  align-self: stretch;
}

.hero--section--title--color {
  color: var(--primary);
}

.hero--section-description {
  color: var(--primary);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  
}

.hero--section--img {
  display: flex;
  justify-content: flex-end;
}

.hero--section--img > img {
  width: 80%;
  height: auto;
  animation: rotate 50s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* Hero Section Style Ends */

  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */
  /* Skills Section Style */

  /* Skills Section Style */
  .skills--section {
    display: flex;
    padding: 0px 15.33px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .skills--section--heading {
    color: var(--primary);
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
  }
  .skills--section--container {
    display: grid;
    justify-content: center;
    align-items: flex-start;
    gap: 42.6px;
    grid-template-columns: repeat(4, 1fr);
    
  }
  .skills--section--card {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    border-radius: 10.6px;
    background: var(--black);
    min-height: 250px;
    position: relative;
  }
  .skills--section--card:hover {
    border-bottom: 4px solid var(--primary);
  }
  .skills--section--card:hover .skills--section--description {
    color: var(--primary);
  }
  .skills--section--img {
    display: flex;
    padding: 13.3px;
    justify-content: center;
    align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: var(--white);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  /* position: absolute; */
  /* top: -100px; */

}
.skills--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.skills--section--title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}
.skills--section--description {
  color: var(--white);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

}
/* Skills Style Ends */

/* About Us */
.about--section {
  display: grid;
  padding: 133.3px 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}
.about--section--img > img {
  width: 80%;
  height: 80%;
 margin-left: 30px;
}
/* About Us Ends */

/* Know your sign */
/* Know your sign */
/* Know your sign */
/* Know your sign */
/* Know your sign */

.kys-container {
  background-color: #006b6a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px; 
  margin-top: 80px;/* Adjust padding for smaller screens */
   /* Ensure the container takes up full height */
}

.kys-input-fields-container {
  width: 100%;
  max-width: 800px; /* Limit the maximum width for large screens */
  display: flex;
  flex-direction: column; /* Stack inputs vertically on small screens */
  gap: 10px; 
  padding: 20px;
  padding-bottom: 100px;
}

.kys-input-fields-container input {
  padding: 15px; /* Adjust padding for smaller screens */
  margin: 4px;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.kys-input-fields-container button {
  padding: 15px;
  width: 100%;
  background-color: #007b7a; /* Adjust button color to match theme */
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.kys-input-fields-container button:hover {
  background-color: #005f5a; /* Darken button color on hover */
}



.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.status-message {
  margin-top: 1rem;
  font-size: 1rem;
  color: rgb(23, 199, 0); /* or any other color you prefer for status messages */
}
/* Media query for larger screens */
@media (min-width: 768px) {
  .kys-input-fields-container {
    flex-direction: row; /* Align inputs horizontally on larger screens */
    flex-wrap: wrap; /* Allow wrapping if there are too many inputs */
    gap: 10px; /* Space between items */
  }
  
  .kys-input-fields-container input {
    flex: 1; /* Allow inputs to grow and shrink */
    min-width: 200px; /* Set a minimum width for inputs */
  }
  
  .kys-input-fields-container button {
    min-width: auto; /* Adjust width to content */
  }
}


/* Testimonial */
/* Testimonial */
/* Testimonial */
/* Testimonial */
/* Testimonial */
/* Testimonial */

.testimonials-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  overflow: hidden;
}

h2 {
  margin-bottom: 20px;
  font-size: 4.5em;
  color: var(--primary);
  margin: 100px 10px;

}

.testimonials-scrollable {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.testimonials-wrapper {
  display: flex;
  animation: scroll 20s linear infinite;
}

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.testimonial-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.testimonial-content {
  flex: 1;
  text-align: left;
  overflow: hidden; /* Ensures content does not overflow */
}

.testimonial-text {
  font-style: italic;
  color: #555;
  margin: 0; /* Remove default margin */
  white-space: normal; /* Allow text to wrap */
  overflow-wrap: break-word; /* Break long words */
}

.testimonial-name {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


/* Testimonial Ends */


/* contact section  */
/* contact section  */
/* contact section  */
/* contact section  */
/* contact section  */
.contact--section {
  display: flex;
  padding: 150px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.checkbox--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
}
input[type="checkbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}
/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: #000000;
  align-items: center;
  align-self: stretch;
  position: relative;
}

.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 12.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
  color: #ffffff;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
  color: #f9f9f9;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgb(255, 170, 0);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--white);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
    /* margin: 100px; */
  }
  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills--section--container {
    gap: 16px;
  }
  .skills--section--card {
    gap: 28px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0px;

    padding: 90.333px 15.333px;

  }
  h2{
    font-size: 40px;
    line-height:50px ;
    margin: 30px 10px;

  }
  h1{
    font-size: 30px;
    line-height:40px ;

  }
  .hero--section--img{
    justify-content: center;
  }
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }
  .skills--section--container,
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .skills--section,
  .portfolio--container,
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .portfolio--container-box {
    gap: 30px;
  }
  .skills--section,
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    /* padding-top: 70px; */
    padding-bottom: 70px;
  }
  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
    padding: 20.3px 15.3px;
  }
  .testimonial--section--card {
    padding: 25px;
  }
  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}

/* Responsive Screens Ends*/




